import { InjectionToken } from '@angular/core';

export * from './variables';
export * from './configuration';
export * from './api/api';
export * from './model/models';

export * from './service/light-partner.service';

export const ITU_CODE = new InjectionToken<string>('ituCode');
