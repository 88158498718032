import { HttpClient, HttpEvent, HttpHeaders, HttpParams, HttpResponse } from '@angular/common/http';
import { Inject, Injectable, Optional } from '@angular/core';
import { Observable } from 'rxjs';
import { ScenarioDto } from '../model/scenarioDto';
import { ClearingDataFormArrayDto } from '../model/clearingDataFormArrayDto';
import { ClearingDataAttribute } from '../model/clearingDataAttribute';
import { Configuration } from '../configuration';
import { BASE_PATH } from '../variables';
import { TargetStateDto } from '../model/targetStateDto';
import { PlatformPartner } from '../model/platformPartner';
import { RoutingConfig } from '../model/routingConfig';
import { SlaPartnerResolutionTime } from '../model/slaPartnerResolutionTime';
import { RoutingConfigTypeEnum } from '../model/routingConfigTypeEnum';
import { Notification } from '../model/notification';
import { UserEligibilityDto } from '../model/userEligibilityDto';
import {  SeverityType } from '../../clearing';
import { CustomHttpUrlEncodingCodec } from '../../clearing/encoder';

@Injectable()
export class ClearingTicketCustomService {
  protected baseConfigPath = '';
  public defaultHeaders = new HttpHeaders();
  public configuration = new Configuration();

  constructor(protected httpClient: HttpClient, @Optional() @Inject(BASE_PATH) baseConfigPath: string, @Optional() configuration: Configuration) {
    if (baseConfigPath) {
      this.baseConfigPath = baseConfigPath;
    }
    if (configuration) {
      this.configuration = configuration;
      this.baseConfigPath = baseConfigPath || configuration.basePath || this.baseConfigPath;
    }
  }


  /**
   * List Scenarios
   * This operation lists all Scenarios with corresponding subscenarios.
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public listScenarios(observe?: 'body', reportProgress?: boolean): Observable<Array<ScenarioDto>>;
  public listScenarios(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<ScenarioDto>>>;
  public listScenarios(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<ScenarioDto>>>;
  public listScenarios(observe: any = 'body', reportProgress = false): Observable<any> {

    let headers = this.defaultHeaders;

    // authentication (basicAuth) required
    if (this.configuration.username || this.configuration.password) {
      headers = headers.set('Authorization', 'Basic ' + btoa(this.configuration.username + ':' + this.configuration.password));
    }

    // authentication (bearer) required
    if (this.configuration.accessToken) {
      const accessToken = typeof this.configuration.accessToken === 'function'
        ? this.configuration.accessToken()
        : this.configuration.accessToken;
      headers = headers.set('Authorization', 'Bearer ' + accessToken);
    }
    // to determine the Accept header
    const httpHeaderAccepts: string[] = [
      'application/json;charset=utf-8'
    ];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];
    return this.httpClient.request<Array<ScenarioDto>>('get', `${this.baseConfigPath}/troubleTicket/scenarios`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * List Create Ticket Scenarios
   * This operation lists all Scenarios with corresponding subscenarios for create ticket form.
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public listCreateTicketScenarios(observe?: 'body', reportProgress?: boolean): Observable<Array<ScenarioDto>>;
  public listCreateTicketScenarios(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<ScenarioDto>>>;
  public listCreateTicketScenarios(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<ScenarioDto>>>;
  public listCreateTicketScenarios(observe: any = 'body', reportProgress = false): Observable<any> {

    let headers = this.defaultHeaders;

    // authentication (basicAuth) required
    if (this.configuration.username || this.configuration.password) {
      headers = headers.set('Authorization', 'Basic ' + btoa(this.configuration.username + ':' + this.configuration.password));
    }

    // authentication (bearer) required
    if (this.configuration.accessToken) {
      const accessToken = typeof this.configuration.accessToken === 'function'
        ? this.configuration.accessToken()
        : this.configuration.accessToken;
      headers = headers.set('Authorization', 'Bearer ' + accessToken);
    }
    // to determine the Accept header
    const httpHeaderAccepts: string[] = [
      'application/json;charset=utf-8'
    ];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];
    return this.httpClient.request<Array<ScenarioDto>>('get', `${this.baseConfigPath}/troubleTicket/scenarios/createTicket`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }


  /**
   * Retrieves number of clearing tickets
   * This operation retrieves number of ClearingTicket entities
   * @param externalId Select only clearing tickets with externalId equal &#x27;externalId&#x27;. An empty parameter is ignored.
   * @param ticketType Select only clearing tickets with scenarios &#x3D; &#x27;ticketType&#x27; (allows pattern: e.g. 1.* selects all scenarios 1.0 — 1.9). An empty parameter is ignored.
   * @param status Select only clearing tickets with status equal &#x27;status&#x27;. An empty parameter is ignored.
   * @param originator Select only clearing tickets with originator equal &#x27;originator&#x27;. An empty parameter is ignored.
   * @param processor Select only clearing tickets with processor equal &#x27;processor&#x27;. An empty parameter is ignored.
   * @param severity Select only clearing tickets with severity equal &#x27;severity&#x27;. An empty parameter is ignored.
   * @param requestedResolutionDateFrom Select only clearing tickets with requestedResolutionDate higher &#x27;requestedResolutionDate&#x27;. An empty parameter is ignored.
   * @param requestedResolutionDateTo Select only clearing tickets with requestedResolutionDate less equal &#x27;requestedResolutionDate&#x27;. An empty parameter is ignored.
   * @param creationDateFrom Select only clearing tickets with creation date equal or higher &#x27;creationDateFrom&#x27; An empty parameter is ignored.
   * @param creationDateTo Select only clearing tickets with creation date less equal &#x27;creationDateTo&#x27; An empty parameter is ignored.
   * @param lastUpdateFrom Select only clearing tickets with last update equal or higher &#x27;lastUpdateFrom&#x27;. An empty parameter is ignored.
   * @param lastUpdateTo Select only clearing tickets with last update less equal &#x27;lastUpdateTo&#x27;. An empty parameter is ignored.
   * @param offset Requested index for start of selected clearing tickets to be provided in response. Note: Sort order is always by creation date.
   * @param limit Requested number of selected clearing tickets to be provided in response. Note: Sort order is always by creation date.
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public countClearingTicket(externalId?: string, ticketType?: string, status?: string, originator?: string, processor?: string, severity?: SeverityType, requestedResolutionDateFrom?: string, requestedResolutionDateTo?: string, creationDateFrom?: string, creationDateTo?: string, lastUpdateFrom?: string, lastUpdateTo?: Date, observe?: 'body', reportProgress?: boolean): Observable<number>;
  public countClearingTicket(externalId?: string, ticketType?: string, status?: string, originator?: string, processor?: string, severity?: SeverityType, requestedResolutionDateFrom?: string, requestedResolutionDateTo?: string, creationDateFrom?: string, creationDateTo?: string, lastUpdateFrom?: string, lastUpdateTo?: Date, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<number>>;
  public countClearingTicket(externalId?: string, ticketType?: string, status?: string, originator?: string, processor?: string, severity?: SeverityType, requestedResolutionDateFrom?: string, requestedResolutionDateTo?: string, creationDateFrom?: string, creationDateTo?: string, lastUpdateFrom?: string, lastUpdateTo?: Date, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<number>>;
  public countClearingTicket(externalId?: string, ticketType?: string, status?: string, originator?: string, processor?: string, severity?: SeverityType, requestedResolutionDateFrom?: string, requestedResolutionDateTo?: string, creationDateFrom?: string, creationDateTo?: string, lastUpdateFrom?: string, lastUpdateTo?: Date, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

    let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
    if (externalId !== undefined && externalId !== null) {
      queryParameters = queryParameters.set('externalId', <any>externalId);
    }
    if (ticketType !== undefined && ticketType !== null) {
      queryParameters = queryParameters.set('ticketType', <any>ticketType);
    }
    if (status !== undefined && status !== null) {
      queryParameters = queryParameters.set('status', <any>status);
    }
    if (originator !== undefined && originator !== null) {
      queryParameters = queryParameters.set('originator', <any>originator);
    }
    if (processor !== undefined && processor !== null) {
      queryParameters = queryParameters.set('processor', <any>processor);
    }
    if (severity !== undefined && severity !== null) {
      queryParameters = queryParameters.set('severity', <any>severity);
    }
    if (requestedResolutionDateFrom !== undefined && requestedResolutionDateFrom !== null) {
      queryParameters = queryParameters.set('requestedResolutionDateFrom', <any>requestedResolutionDateFrom);
    }
    if (requestedResolutionDateTo !== undefined && requestedResolutionDateTo !== null) {
      queryParameters = queryParameters.set('requestedResolutionDateTo', <any>requestedResolutionDateTo);
    }
    if (creationDateFrom !== undefined && creationDateFrom !== null) {
      queryParameters = queryParameters.set('creationDateFrom', <any>creationDateFrom);
    }
    if (creationDateTo !== undefined && creationDateTo !== null) {
      queryParameters = queryParameters.set('creationDateTo', <any>creationDateTo);
    }
    if (lastUpdateFrom !== undefined && lastUpdateFrom !== null) {
      queryParameters = queryParameters.set('lastUpdateFrom', <any>lastUpdateFrom);
    }
    if (lastUpdateTo !== undefined && lastUpdateTo !== null) {
      queryParameters = queryParameters.set('lastUpdateTo', <any>lastUpdateTo.toISOString());
    }

    let headers = this.defaultHeaders;

    // authentication (basicAuth) required
    if (this.configuration.username || this.configuration.password) {
      headers = headers.set('Authorization', 'Basic ' + btoa(this.configuration.username + ':' + this.configuration.password));
    }

    // authentication (bearer) required
    if (this.configuration.accessToken) {
      const accessToken = typeof this.configuration.accessToken === 'function'
        ? this.configuration.accessToken()
        : this.configuration.accessToken;
      headers = headers.set('Authorization', 'Bearer ' + accessToken);
    }
    // to determine the Accept header
    let httpHeaderAccepts: string[] = [
      'application/json;charset=utf-8'
    ];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
    ];

    return this.httpClient.request<number>('get',`${this.baseConfigPath}/troubleTicket/count`,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }


  /**
   * Retrieve a Clearing Ticket Possible target states by ID
   * This operation retrieves a Clearing Ticket Possible target states
   * @param id Identifier of the ClearingTicket
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public listPossibleTargetStates(id: string, observe?: 'body', reportProgress?: boolean): Observable<Array<TargetStateDto>>;
  public listPossibleTargetStates(id: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<TargetStateDto>>>;
  public listPossibleTargetStates(id: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<TargetStateDto>>>;
  public listPossibleTargetStates(id: string, observe: any = 'body', reportProgress = false): Observable<any> {

    if (id === null || id === undefined) {
      throw new Error('Required parameter id was null or undefined when calling listPossibleTargetStates.');
    }

    let headers = this.defaultHeaders;

    // authentication (basicAuth) required
    if (this.configuration.username || this.configuration.password) {
      headers = headers.set('Authorization', 'Basic ' + btoa(this.configuration.username + ':' + this.configuration.password));
    }

    // authentication (bearer) required
    if (this.configuration.accessToken) {
      const accessToken = typeof this.configuration.accessToken === 'function'
        ? this.configuration.accessToken()
        : this.configuration.accessToken;
      headers = headers.set('Authorization', 'Bearer ' + accessToken);
    }
    // to determine the Accept header
    const httpHeaderAccepts: string[] = [
      'application/json;charset=utf-8'
    ];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];
    return this.httpClient.request<Array<TargetStateDto>>('get', `${this.baseConfigPath}/troubleTicket/${encodeURIComponent(String(id))}/available-states`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * Retrieve a Clearing Ticket Clearing Data  by Scenario ID
   * This operation retrieves a  Clearing Ticket Clearing Data
   * @param id Identifier of the ClearingTicket Scenario
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public listClearingDataByScenarioId(id: string, observe?: 'body', reportProgress?: boolean): Observable<Array<ClearingDataAttribute>>;
  public listClearingDataByScenarioId(id: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<ClearingDataAttribute>>>;
  public listClearingDataByScenarioId(id: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<ClearingDataAttribute>>>;
  public listClearingDataByScenarioId(id: string, observe: any = 'body', reportProgress = false): Observable<any> {

    if (id === null || id === undefined) {
      throw new Error('Required parameter id was null or undefined when calling listClearingDataByScenarioId.');
    }

    let headers = this.defaultHeaders;

    // authentication (basicAuth) required
    if (this.configuration.username || this.configuration.password) {
      headers = headers.set('Authorization', 'Basic ' + btoa(this.configuration.username + ':' + this.configuration.password));
    }

    // authentication (bearer) required
    if (this.configuration.accessToken) {
      const accessToken = typeof this.configuration.accessToken === 'function'
        ? this.configuration.accessToken()
        : this.configuration.accessToken;
      headers = headers.set('Authorization', 'Bearer ' + accessToken);
    }
    // to determine the Accept header
    const httpHeaderAccepts: string[] = [
      'application/json;charset=utf-8'
    ];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];
    return this.httpClient.request<Array<ClearingDataAttribute>>('get', `${this.baseConfigPath}/troubleTicket/scenarios/${encodeURIComponent(String(id))}/clearingData`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * Retrieve a Clearing Ticket Clearing Data Form Array  by Scenario ID for specific ClearingDataAttribute
   * This operation retrieves a  Clearing Ticket Clearing Data for specific attribute
   * @param scenarioId Identifier of the ClearingTicket Scenario
   * @param attributeName Identifier of the Specific ClearingDataAttribute
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public listClearingDataByScenarioIdAndAttributeName(scenarioId: string, attributeName: string, observe?: 'body', reportProgress?: boolean): Observable<ClearingDataFormArrayDto>;
  public listClearingDataByScenarioIdAndAttributeName(scenarioId: string, attributeName: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ClearingDataFormArrayDto>>;
  public listClearingDataByScenarioIdAndAttributeName(scenarioId: string, attributeName: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ClearingDataFormArrayDto>>;
  public listClearingDataByScenarioIdAndAttributeName(scenarioId: string, attributeName: string, observe: any = 'body', reportProgress = false): Observable<any> {

    if (scenarioId === null || scenarioId === undefined) {
      throw new Error('Required parameter id was null or undefined when calling listClearingDataByScenarioIdAndAttributeName.');
    }

    if (attributeName === null || attributeName === undefined) {
      throw new Error('Required parameter attributeName was null or undefined when calling listClearingDataByScenarioIdAndAttributeName.');
    }

    let headers = this.defaultHeaders;

    // authentication (basicAuth) required
    if (this.configuration.username || this.configuration.password) {
      headers = headers.set('Authorization', 'Basic ' + btoa(this.configuration.username + ':' + this.configuration.password));
    }

    // authentication (bearer) required
    if (this.configuration.accessToken) {
      const accessToken = typeof this.configuration.accessToken === 'function'
        ? this.configuration.accessToken()
        : this.configuration.accessToken;
      headers = headers.set('Authorization', 'Bearer ' + accessToken);
    }
    // to determine the Accept header
    const httpHeaderAccepts: string[] = [
      'application/json;charset=utf-8'
    ];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];
    return this.httpClient.request<Array<ClearingDataFormArrayDto>>('get', `${this.baseConfigPath}/troubleTicket/scenarios/${encodeURIComponent(String(scenarioId))}/${encodeURIComponent(String(attributeName))}/clearingData`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  public listPlatformPartnerConfiguration() {
    return this.httpClient.request<Array<PlatformPartner>>('get', `${this.baseConfigPath}/platformPartner`);
  }

  public retrievePlatformPartnerConfiguration(id: string) {
    return this.httpClient.request<PlatformPartner>('get', `${this.baseConfigPath}/platformPartner/${id}`);
  }

  public createPlatformPartnerConfiguration(platformPartnerCreate: PlatformPartner) {
    return this.httpClient.request<PlatformPartner>('post', `${this.baseConfigPath}/platformPartner`, {
      body: platformPartnerCreate
    });
  }

  public updatePlatformPartnerConfiguration(id: number, platformPartnerUpdate: PlatformPartner) {
    return this.httpClient.request<PlatformPartner>('patch', `${this.baseConfigPath}/platformPartner/${id}`, {
      body: platformPartnerUpdate
    });
  }

  public listMyNotifications() {
    return this.httpClient.request<Notification[]>('get', `${this.baseConfigPath}/troubleTicket/notifications`);
  }

  public readMyNotificationsByTicketId(ticketId: string) {
    return this.httpClient.request('post', `${this.baseConfigPath}/troubleTicket/notifications/${ticketId}`);
  }

  public markAllMyNotificationsAsRead() {
    return this.httpClient.request('post', `${this.baseConfigPath}/troubleTicket/notifications`);
  }

  public createRoutingConfig(routingConfigCreate: RoutingConfig) {
    return this.httpClient.request<RoutingConfig>('post', `${this.baseConfigPath}/routing-config`, {
      body: routingConfigCreate
    });
  }

  public listTicketActionsEligibleUsers(clearingTicketId: number) {
    return this.httpClient.get<UserEligibilityDto>(`${this.baseConfigPath}/troubleTicket/userEligibility/${clearingTicketId}`);
  }

  public listLightTicketActionsEligibleUsers(clearingTicketId: number) {
    return this.httpClient.get<UserEligibilityDto>(`${this.baseConfigPath}/light/troubleTicket/userEligibility/${clearingTicketId}`);
  }

  public listRoutingConfig(type: RoutingConfigTypeEnum, page?: number, pageSize?: number) {
    return this.httpClient.request<Array<RoutingConfig>>('get', `${this.baseConfigPath}/routing-config`, {
      params: {
        page: page || 0,
        pageSize: pageSize || 10,
        type: type
      }
    });
  }

  public retrieveRoutingConfig(id: string) {
    return this.httpClient.request<RoutingConfig>('get', `${this.baseConfigPath}/routing-config/${id}`);
  }

  public retrieveRoutingConfigByScenario(scenarioId: string) {
    return this.httpClient.request<RoutingConfig[]>('get', `${this.baseConfigPath}/routing-config/scenario/${scenarioId}`);
  }

  public updateRoutingConfig(id: number, routingConfigUpdate: RoutingConfig) {
    return this.httpClient.request<RoutingConfig>('put', `${this.baseConfigPath}/routing-config/${id}`, {
      body: routingConfigUpdate
    });
  }

  public deleteRoutingConfig(id: number) {
    return this.httpClient.request('delete', `${this.baseConfigPath}/routing-config/${id}`);
  }

  public createSlaConfig(slaConfigCreate: SlaPartnerResolutionTime) {
    return this.httpClient.request<SlaPartnerResolutionTime>('post', `${this.baseConfigPath}/sla`, {
      body: slaConfigCreate
    });
  }

  public listSlaConfig(page?: number, pageSize?: number) {
    return this.httpClient.request<SlaPartnerResolutionTime[]>('get', `${this.baseConfigPath}/sla`, {
      params: {
        page: page || 0,
        pageSize: pageSize || 10
      }
    });
  }

  public updateSlaConfig(id: number, slaConfigUpdate: SlaPartnerResolutionTime) {
    return this.httpClient.request<RoutingConfig>('put', `${this.baseConfigPath}/sla/${id}`, {
      body: slaConfigUpdate
    });
  }

  public retrieveSlaConfig(id: string) {
    return this.httpClient.request<SlaPartnerResolutionTime>('get', `${this.baseConfigPath}/sla/${id}`);
  }

  public deleteSlaConfig(id: number) {
    return this.httpClient.request('delete', `${this.baseConfigPath}/sla/${id}`);
  }

  public listAccessRoles() {
    return this.httpClient.request<string[]>('get', `${this.baseConfigPath}/access/roles`);
  }

  sync(id: string) {
    return this.httpClient.request('patch', `${this.baseConfigPath}/troubleTicket/${id}/sync`);
  }

  bulkSync(ids: string[]) {
    return this.httpClient.request('patch', `${this.baseConfigPath}/troubleTicket/sync`, {
      body: ids
    });
  }
}
