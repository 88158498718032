import { Inject, Injectable, Optional } from '@angular/core';
import { HttpClient, HttpEvent, HttpHeaders, HttpParams, HttpResponse } from '@angular/common/http';
import { ClearingTicket, Configuration, SeverityType, StatusType } from '@adlatus-gui/domain/clearing';
import {
  BASE_PATH,
  ClearingTicketAssignTicketCreate,
  ClearingTicketBulkAssignTicketCreate,
  UserRepresentation
} from '@adlatus-gui/domain/clearing-custom';
import { ClearingTicketAssignmentResponse } from '../model/clearingTicketAssignmentResponse';
import { CustomHttpUrlEncodingCodec } from '../../clearing/encoder';
import { Observable } from 'rxjs';
import { RoutingConfigTypeEnum } from '../model/routingConfigTypeEnum';

@Injectable({
  providedIn: 'root'
})
export class ClearingTicketAssignmentService {
  protected baseConfigPath = '';
  public defaultHeaders = new HttpHeaders();
  public configuration = new Configuration();

  constructor(protected httpClient: HttpClient, @Optional() @Inject(BASE_PATH) baseConfigPath: string, @Optional() configuration: Configuration) {
    if (baseConfigPath) {
      this.baseConfigPath = baseConfigPath;
    }
    if (configuration) {
      this.configuration = configuration;
      this.baseConfigPath = baseConfigPath || configuration.basePath || this.baseConfigPath;
    }
  }


  public listPossibleAssignees(scenarioId: string, routingConfigType: RoutingConfigTypeEnum) {
    const queryParameters = {
      routingConfigType: routingConfigType
    };
    return this.httpClient.request<Array<UserRepresentation>>('get', `${this.baseConfigPath}/troubleTicket/assignees/${scenarioId}`,
      {
        params: queryParameters
      });
  }


  public listPossibleBulkAssignees(clearingTickets: ClearingTicket[]) {
    return this.httpClient.request<Array<UserRepresentation>>('post', `${this.baseConfigPath}/troubleTicket/assignees`, {
      body: {
        clearingTickets: clearingTickets
      }
    });
  }

  public assignTicket(assignTicketCreate: ClearingTicketAssignTicketCreate) {
    return this.httpClient.request<ClearingTicketAssignmentResponse>('post', `${this.baseConfigPath}/troubleTicket/assign`, {
      body: assignTicketCreate
    });
  }


  public createBulkAssign(assignBulkcreate: ClearingTicketBulkAssignTicketCreate) {
    return this.httpClient.request<ClearingTicketAssignmentResponse[]>('post', `${this.baseConfigPath}/troubleTicket/bulk-assign`, {
      body: assignBulkcreate
    });
  }

  public retrieveTicketAssignee(ticketId: number) {
    return this.httpClient.request<UserRepresentation>('get', `${this.baseConfigPath}/troubleTicket/${ticketId}/assignee`);
  }

  /**
   * Lists or finds ClearingTicket objects
   * This operation lists ClearingTicket entities current user is watching
   * @param ticketType Select only clearing tickets with scenarios &#x3D; &#x27;ticketType&#x27; (allows pattern: e.g. 1.* selects all scenarios 1.0 — 1.9). An empty parameter is ignored.
   * @param status Select only clearing tickets with status equal &#x27;status&#x27;. An empty parameter is ignored.
   * @param originator Select only clearing tickets with originator equal &#x27;originator&#x27;. An empty parameter is ignored.
   * @param processor Select only clearing tickets with processor equal &#x27;processor&#x27;. An empty parameter is ignored.
   * @param severity Select only clearing tickets with severity equal &#x27;severity&#x27;. An empty parameter is ignored.
   * @param creationDateFrom Select only clearing tickets with creation date  An empty parameter is ignored.
   * @param creationDateTo Select only clearing tickets with creation date  An empty parameter is ignored.
   * @param page Requested index for start of selected clearing tickets to be provided in response. Note: Sort order is always by creation date.
   * @param size Requested number of selected clearing tickets to be provided in response. Note: Sort order is always by creation date.
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public listMyIssues(ticketType?: string, status?: StatusType, originator?: string, processor?: string, severity?: SeverityType, creationDateFrom?: string, creationDateTo?: string, page?: number, size?: number, observe?: 'body', reportProgress?: boolean): Observable<Array<ClearingTicket>>;
  public listMyIssues(ticketType?: string, status?: StatusType, originator?: string, processor?: string, severity?: SeverityType, creationDateFrom?: string, creationDateTo?: string, page?: number, size?: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<ClearingTicket>>>;
  public listMyIssues(ticketType?: string, status?: StatusType, originator?: string, processor?: string, severity?: SeverityType, creationDateFrom?: string, creationDateTo?: string, page?: number, size?: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<ClearingTicket>>>;
  public listMyIssues(ticketType?: string, status?: StatusType, originator?: string, processor?: string, severity?: SeverityType, creationDateFrom?: string, creationDateTo?: string, page?: number, size?: number, observe: any = 'body', reportProgress: boolean = false): Observable<any> {
    let queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });

    if (ticketType !== undefined && ticketType !== null) {
      queryParameters = queryParameters.set('ticketType', <any>ticketType);
    }
    if (status !== undefined && status !== null) {
      queryParameters = queryParameters.set('status', <any>status);
    }
    if (originator !== undefined && originator !== null) {
      queryParameters = queryParameters.set('originator', <any>originator);
    }
    if (processor !== undefined && processor !== null) {
      queryParameters = queryParameters.set('processor', <any>processor);
    }
    if (severity !== undefined && severity !== null) {
      queryParameters = queryParameters.set('severity', <any>severity);
    }

    if (creationDateFrom !== undefined && creationDateFrom !== null) {
      queryParameters = queryParameters.set('creationDateFrom', <any>creationDateFrom);
    }

    if (creationDateTo !== undefined && creationDateTo !== null) {
      queryParameters = queryParameters.set('creationDateTo', <any>creationDateTo);
    }

    if (page !== undefined && page !== null) {
      queryParameters = queryParameters.set('offset', <any>page);
    }
    if (size !== undefined && size !== null) {
      queryParameters = queryParameters.set('limit', <any>size);
    }

    let headers = this.defaultHeaders;

    // authentication (basicAuth) required
    if (this.configuration.username || this.configuration.password) {
      headers = headers.set('Authorization', 'Basic ' + btoa(this.configuration.username + ':' + this.configuration.password));
    }

    // authentication (bearer) required
    if (this.configuration.accessToken) {
      const accessToken = typeof this.configuration.accessToken === 'function'
        ? this.configuration.accessToken()
        : this.configuration.accessToken;
      headers = headers.set('Authorization', 'Bearer ' + accessToken);
    }
    // to determine the Accept header
    let httpHeaderAccepts: string[] = [
      'application/json;charset=utf-8'
    ];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.request<Array<ClearingTicket>>('get', `${this.baseConfigPath}/troubleTicket/myIssues`,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }
}
