/**
 * Clearing Platform API
 * The Clearing Platform API (for Partner Access) provides a standardized client interface to a Clearing Platform Systems for creating and tracking clearing tickets. It also contains access to metadata like ItuCarrier  and supports attachments (store and retrieve)
 *
 * OpenAPI spec version: 1.0.0
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

/**
 * The severity of the clearing ticket. Severity is one of the following.
 */
export type SeverityType = 'regular' | 'escalated' | 'critical';

export const SeverityType = {
  Regular: 'regular' as SeverityType,
  Critical: 'critical' as SeverityType,
  Escalated: 'escalated' as SeverityType
};
