import { Router } from '@angular/router';
import { Inject, Injectable } from '@angular/core';
import { catchError, from, Observable, of, switchMap, throwError } from 'rxjs';
import { AuthenticationService } from '@adlatus-gui/domain/authentication';
import { LIGHT_PARTNER_API_PATH } from '@adlatus-gui/domain/clearing-custom';
import { roles } from '../../authentication/constant/roles';


@Injectable({
  providedIn: 'root'
})
export class LightPartnerService {

  constructor(
    private router: Router,
    private authenticationService: AuthenticationService,
    @Inject(LIGHT_PARTNER_API_PATH) private lightPartnerApiPath: string) {
  }

  getIsLightPartner() {
    return this.authenticationService.getUserRoles().includes(roles.CLEARING_LIGHT);
  }

  getIsLightPartnerRoute() {
    return this.router.url.includes('light');
  };

  getLightPartnerItuCode(): Observable<string | null> {
    return from(this.authenticationService.getAccessToken()).pipe(
      switchMap(token => {
        try {
          const parsedToken = JSON.parse(atob(token.split('.')[1]));
          const partnerCode = parsedToken?.['groups'] ? parsedToken['groups'][0].replace('/', '') : null;
          return of(partnerCode);
        } catch (error) {
          console.error('Error parsing token:', error);
          return throwError(() => new Error('Failed to parse token.'));
        }
      }),
      catchError(error => {
        console.error('An error occurred:', error);
        return throwError(() => new Error('Something went wrong; please try again later.'));
      })
    );
  }

  applyLightPartnerApi(basePath: string) {
    return basePath + this.lightPartnerApiPath;
  };
}


